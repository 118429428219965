import { useQuery } from 'react-query';

import { request } from 'api/request';

import { ERROR_REASONS } from 'utils/logger/transports/constants';

import { logger } from 'utils/logger';

const apiRequest = async (selectedUserId?: string) => {
    return await request(`admin/users/${selectedUserId}`);
};

export const useGetUser = (selectedUserId?: string) => {
    return useQuery(['user', selectedUserId], async () => apiRequest(selectedUserId), {
        refetchOnWindowFocus: false,
        enabled: Boolean(selectedUserId),
        onError(err: any) {
            logger.error(Error('Error fetching user:'), {
                cause: err,
                tags: {
                    reason: ERROR_REASONS.getUser,
                    errorCode: err.status
                }
            });
        }
    });
};
