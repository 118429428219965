import { useMutation } from 'react-query';

import { request } from 'api/request';

import { ERROR_REASONS } from 'utils/logger/transports/constants';
import { ProjectsNames } from 'hooks/permission/constants';

import { logger } from 'utils/logger';

type MutateProps = { userId: string; projectId?: number; body: { groups: string[] } };

const apiRequest = async (payload: MutateProps) => {
    return await request(`admin/users/${payload.userId}`, {
        method: 'PUT',
        body: JSON.stringify(payload.body),
        headers: payload?.projectId ? { project_name: ProjectsNames[payload.projectId] } : {}
    });
};

export const usePutUser = ({ onSuccess, onError }: { onSuccess: () => void; onError: () => void }) => {
    const { mutate, isLoading: isLoadingOnMutate } = useMutation<unknown, unknown, MutateProps>({
        mutationFn: async (payload) => apiRequest(payload),
        onSuccess,
        onError: (err: any) => {
            onError();
            logger.error(Error('Error updating user:'), {
                cause: err,
                tags: {
                    reason: ERROR_REASONS.updatingUser,
                    errorCode: err?.status
                }
            });
        }
    });

    return { mutate, isLoadingOnMutate };
};
