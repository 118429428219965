import { useQuery } from 'react-query';

import { request } from 'api/request';

import { ERROR_REASONS } from 'utils/logger/transports/constants';

import { logger } from 'utils/logger';
import { cleanSearchParams } from 'utils/common';

import { ThemeSearchParams } from 'pages/theme/types';

const apiRequest = async (searchParams: ThemeSearchParams) => {
    return await request('admin/users', { params: { ...cleanSearchParams(searchParams) } });
};

export const useGetUsersList = (searchParams: ThemeSearchParams, onSuccess: (data: any) => void) => {
    return useQuery(['users', searchParams], async () => apiRequest(searchParams), {
        refetchOnWindowFocus: false,
        onSuccess: (data: any) => {
            onSuccess(data);
        },
        onError: (err: any) => {
            logger.error(Error('Error fetching users list:'), {
                cause: err,
                tags: {
                    reason: ERROR_REASONS.getUserList,
                    errorCode: err.status
                }
            });
        }
    });
};
